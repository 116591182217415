import PropTypes from "prop-types";
import "./style.css";
import classNames from "classnames";
import { MGIcon } from "@/components/icons";
import { MGTooltip } from "@/components/tooltip";
import { useMGTranslate } from "@/components/translations/useMGTranslate";

export const MGInputListItem = ({ value, removable, children, disabled, onRemoveClick }) => {
  const onRemoveClickHandler = () => {
    //logger.log(value);
    if (typeof onRemoveClick === "function") {
      onRemoveClick(value);
    }
  };

  const [t] = useMGTranslate();

  return (
    <div className="--mg-input-list-item-container">
      <div
        className={`${classNames({
          "--mg-content-container": true,
          disabled: disabled,
        })}`}
      >
        {children}
      </div>
      <div className="--mg-flex-box-spacer"></div>
      {removable && (
        <MGTooltip text={t("Remove")} position={"bottom"}>
          <MGIcon disabled={disabled} icon="trash" iconSize={16} onClick={onRemoveClickHandler}></MGIcon>
        </MGTooltip>
      )}
    </div>
  );
};

MGInputListItem.propTypes = {
  removable: PropTypes.bool,
  disabled: PropTypes.bool,
};

MGInputListItem.defaultProps = {
  removable: true,
  disabled: false,
};

export default MGInputListItem;
