import { currentUserVar } from "@/cache";
import config from "@/config";
import { StringCodec, connect, ErrorCode, Empty, nkeyAuthenticator, Events, DebugEvents } from "nats.ws";
import { logger } from "@/app/services/logging/Logger";

const NAMESPACE = "[NatsService]";

export class NatsService {
  #nidKey = "MG-NID";
  static #instance;
  failedPublishCallback;
  #servers = {
    servers: `${config.NATS_SERVER_WEBSOCKET}`,

    noEcho: true,
  };

  #userPassAuthentication = {
    user: config.NATS_TEACHER_USER_NAME,
    pass: config.NATS_TEACHER_PASS,
  };

  bc = new BroadcastChannel("mg-teacher-dashboard__nats_status_channel");

  #credsAuthentication = {
    authenticator: nkeyAuthenticator(new TextEncoder().encode(config.NATS_TEACHER_AUTH_SEED)),
  };

  natsClient;
  static sc = new StringCodec();
  currentUser = currentUserVar();
  attemptingToReconnect = false;
  pollingInterval = null;
  initialized = false;
  initializing = false;

  /**
   * @deprecated
   * Please use the getInstance() method to avoid multiple instances of this class
   */
  constructor() {}

  /**
   * Gets the current instance of the NatsService
   * @returns {NatsService} NatsService
   */
  static getInstance() {
    if (!NatsService.#instance) {
      NatsService.#instance = new NatsService();
    }
    return NatsService.#instance;
  }

  #uuid() {
    let nid = localStorage.getItem(this.#nidKey);
    if (!nid) {
      nid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
      localStorage.setItem(this.#nidKey, nid);
    }
    return nid;
  }
  /**
   * @returns {string} the nats client client ID
   */
  get clientId() {
    return this.natsClient?.info?.client_id;
  }

  /**
   * @returns {string} the signature for the teacher when sending nats messages
   */
  get teacherSignature() {
    return `teacher-${this.#uuid()}`;
  }

  setupNatsEvents() {
    // handle connection to the server is closed - should disable the ui
    if (this.natsClient != null && this.natsClient != undefined) {
      this.natsClient?.closed().then((err) => {
        let m = "NATS connection closed";
        if (err) {
          logger.error(NAMESPACE, m, err.message);
        } else {
          logger.error(NAMESPACE, m, "gracefully");
        }
        this.natsClient = undefined;
      });

      (async () => {
        this.attemptingToReconnect = false;
        for await (const s of this.natsClient.status()) {
          switch (s.type) {
            case Events.Error:
              logger.error(NAMESPACE, `${s.type}`, s.data, s.permissionContext);
              if (s.data === "NATS_PROTOCOL_ERR") {
                this.natsClient?.close();
                this.natsClient = undefined;
                return;
              }
              break;
            case DebugEvents.StaleConnection:
              logger.warn(NAMESPACE, "connection has gone stale. Closing connection.");
              this.natsClient?.close();
              this.natsClient = undefined;
              return;
            case Events.Disconnect:
              logger.debug(NAMESPACE, `client disconnected - ${s.data}`);
              this.attemptingToReconnect = true;
              this.pollingInterval = setInterval(() => {
                if (this.attemptingToReconnect == false) {
                  if (window.navigator.onLine) {
                    this.bc.postMessage("reconnect");
                    clearInterval(this.pollingInterval);
                  }
                } else {
                  this.init();
                }
              }, 7000);
              break;
            case Events.LDM:
              logger.debug(NAMESPACE, "client has been requested to reconnect");
              break;
            case Events.Update:
              logger.debug(NAMESPACE, `client received a cluster update - ${s.data}`);
              break;
            case Events.Reconnect:
              this.attemptingToReconnect = false;
              logger.debug(NAMESPACE, `client reconnected - ${s.data}`);
              break;
            case DebugEvents.Reconnecting:
              logger.debug(NAMESPACE, "client is attempting to reconnect");
              break;
            default:
              logger.debug(NAMESPACE, `STATUS Update: ${s.type}`, s.data);
              break;
          }
        }
      })().then(() => logger.warn(NAMESPACE, "Status Listener Stopped!"));
    }
  }

  async init() {
    try {
      if (this.initializing) {
        logger.debug(NAMESPACE, `already initializing!`);
        return;
      }
      this.initializing = true;
      if (this.pollingInterval) {
        clearInterval(this.pollingInterval);
      }
      if (this.natsClient) {
        logger.debug(NAMESPACE, `already initialised!`);
        this.initializing = false;
        return;
      }
      logger.debug(NAMESPACE, `connecting to nats server: ${this.#servers.servers}...`);

      let authentication = null;
      if (!config.NATS_TEACHER_AUTH_SEED || config.NATS_TEACHER_AUTH_SEED == "") {
        authentication = this.#userPassAuthentication;
        logger.debug(NAMESPACE, `No NATS auth seed available, authenticating with user/pass`, {
          ...this.#servers,
          user: config.NATS_TEACHER_USER_NAME,
        });
      } else {
        authentication = this.#credsAuthentication;
        logger.debug(NAMESPACE, `NATS auth seed detected, authenticating with nkey authenticator`, {
          ...this.#servers,
        });
      }

      this.natsClient = await connect({
        ...this.#servers,
        ...authentication,
        noEcho: true,
        pingInterval: 30000,
      });
      logger.debug(NAMESPACE, `connected to nats server: ${this.#servers.servers}`);
      this.natsClient?.flush();
      this.setupNatsEvents();
      this.initialized = true;
      this.initializing = false;
      if (window.navigator.onLine) {
        this.bc.postMessage("reconnect");
      }
    } catch (e) {
      logger.error(NAMESPACE, `ERROR => ${e}`);
    }
  }

  /**
   * this will convert and return the sanatized message from a nats subsctiption
   * @param {byte[]} message
   * @returns {object | null}
   */
  sanitizeMessage(messageData) {
    const messageString = NatsService.sc.decode(messageData.data);
    try {
      const message = JSON.parse(messageString);
      if (message.targets != null) {
        if (Array.isArray(message.targets) && message.targets.length > 0) {
          if (message.targets.indexOf(this.teacherSignature) < 0) {
            return null;
          }
        }
      }

      if (message.sender !== this.teacherSignature) {
        return message;
      }
    } catch (e) {
      return null;
    }
    return null;
  }

  /**
   *
   * @param {string} channel
   * @param {NatsMessage object} message
   */
  publish(channel, message) {
    const messageData = {
      sender: this.teacherSignature,
      ...message,
    };

    try {
      this.natsClient.publish(channel, NatsService.sc.encode(JSON.stringify(messageData)));
      this.getChannelVars(channel).then((result)=>{
        this.validateNatsChannel(result, channel)
      }).catch((e)=>{
        this.failedPublishCallback();
        logger.error(`[NatsService] Error publishing to channel ${channel}`,e);
      });
    } catch (e) {
      logger.error(`[NatsService] Error publishing to channel ${channel}`,e);
      this.failedPublishCallback();
      // this.checkConnection();
    }

    if (message?.type && message.type != "ping") {
      logger.debug(NAMESPACE, `published message to ${channel}`, message);
    }

    // if (message.type == "refresh") {
    //   const stackTrace = new Error();
    //   logger.log("Cause of TabControl refresh: ", stackTrace);
    // }
  }

  /**
   * Creates a nats subscription to listen to
   * @param {string} channel
   * @returns {Promise<Subscription>}
   */
  subscribe(channel) {
    logger.debug(NAMESPACE, `subscribing to ${channel}`);
    try {
      let sub = this.natsClient.subscribe(channel);
      logger.debug(NAMESPACE, `subscribed to ${channel}`);
      return sub;
    } catch (e) {
      logger.error(e);
      return null;
    }
  }

  async checkConnection() {
    if (!this.natsClient || this.natsClient === undefined) {
      logger.warn(NAMESPACE, "Nats connection doesn't not exist. Creating");
      await this.init();
    } else {
      if (this.natsClient.isClosed()) {
        logger.warn(NAMESPACE, "Nats connection is closed, establishing connection...");
        await this.init();
      }
    }
  }

  async checkTopic(channel) {
    try {
      const m = await this.natsClient.request(channel, Empty, { timeout: 1000 });
      logger.debug("[NatsService] check topic response", m.data);
      return "OK";
    } catch (err) {
      switch (err.code) {
        case ErrorCode.NoResponders:
          logger.debug(`[NatsService] no one is listening to ${channel}`);
          break;
        case ErrorCode.Timeout:
          logger.debug("[NatsService] someone is listening but didn't respond");
          break;
        default:
          logger.debug("[NatsService] request failed", err);
      }
      return err.code;
    }
  }

  setFailedPublishCallback(callback) {
    this.failedPublishCallback = callback;
  }

  /**
   * Extracts variables from NATS channels in a readable way
   * Patterns: 
   *   "school.{schoolId}"
   *   "school.{schoolId}.class.{classId}"
   *   "school.{schoolId}.device.{deviceUuid}"
   *   "school.{schoolId}.class.{classId}.device.{deviceUuid}"
   */
  async getChannelVars(channel) {
    const result = { schoolId: null, classId: null, deviceUuid: null };
    
    // Get position and value of schoolId
    const schoolPrefix = "school.";
    const schoolStart = channel.indexOf(schoolPrefix);
    if (schoolStart === -1) return result;
    
    const afterSchool = schoolStart + schoolPrefix.length;
    const nextDotAfterSchool = channel.indexOf('.', afterSchool);
    result.schoolId = nextDotAfterSchool === -1 
        ? channel.slice(afterSchool) 
        : channel.slice(afterSchool, nextDotAfterSchool);
    
    // Get position and value of classId
    const classPrefix = ".class.";
    const classStart = channel.indexOf(classPrefix, afterSchool);
    if (classStart === -1) return result;
    
    const afterClass = classStart + classPrefix.length;
    const nextDotAfterClass = channel.indexOf('.', afterClass);
    result.classId = nextDotAfterClass === -1 
        ? channel.slice(afterClass) 
        : channel.slice(afterClass, nextDotAfterClass);
    
    // Get position and value of deviceUuid
    const devicePrefix = ".device.";
    const deviceStart = channel.indexOf(devicePrefix, afterClass);
    if (deviceStart === -1) return result;
    
    const afterDevice = deviceStart + devicePrefix.length;
    const nextDotAfterDevice = channel.indexOf('.', afterDevice);
    result.deviceUuid = nextDotAfterDevice === -1 
        ? channel.slice(afterDevice) 
        : channel.slice(afterDevice, nextDotAfterDevice);
    
    return result;
  }

  validateNatsChannel(result, channel){
    const { schoolId, classId, deviceUuid } = result;

    if(schoolId)  //There MUST be a school ID in a channel and it must be numeric
    {
      if(isNaN(schoolId)){
        throw "Invalid schoolID provided"
      }
    } else {
      throw "SchoolID not provided"
    }
    
    if(classId) //ClassId CAN be null e.g for channel 'school.1234.device.123454635' but MUST be numeric
    {
      if(isNaN(classId)){
        throw "Invalid classID provided"
      }
    } else {
      if(channel.indexOf('class.') !== -1){
        throw "ClassID not provided"
      }
    }

    if(deviceUuid)//DeviceUUid CAN be null, but must be truthy and lengthy
    {
      if(deviceUuid.length < 7)
        {
          throw "Invalid deviceUuid provided"
        }
      }
  }
}
