import { Form, Formik } from "formik";
import { MGHeading2, MGHelperText, MGLabel } from "@/components/typography";
import { MGRadioField, MGRadioGroupField } from "@/components/controls";
import { MGModal, MGModalBody, MGModalFooter, MGModalHeader } from "@/components/modals";
import { MGButton } from "@/components/buttons";
import { MGSpacing } from "@/components/spacing";

import PropTypes from "prop-types";
import { MGDurationPickerField } from "@/components/inputs";
import * as yup from "yup";
import { useTranslation } from "react-i18next";

export const ClassEndModal = ({ className, isShowing, initialValues, onSubmit, onCancel }) => {
  const { t } = useTranslation();

  const validationSchema = yup.object({
    // hours: yup.number().when("endClassMethod", {
    //   is: "extend",
    //   then: yup.number().typeError(null).min(0, t("Invalid")).max(23, t("Invalid")).required(t("Required")),
    // }),
    // minutes: yup.number().when("endClassMethod", {
    //   is: "extend",
    //   then: yup.number().typeError(null).min(0, t("Invalid")).max(59, t("Invalid")).required(t("Required")),
    // }),
    duration: yup.number().when("endClassMethod", {
      is: "extend",
      then: yup.number().typeError(null).min(0, t("Invalid")).max(1440, t("Invalid")).required(t("Required")),
    }),
  });

  return (
    <MGModal isShowing={isShowing}>
      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {(props) => {
          const { values, isValid, isSubmitting } = props;

          return (
            <Form noValidate autoComplete={"off"}>
              <MGModalHeader>
                <MGHeading2>{className}</MGHeading2>
                <MGHelperText>{t("End your class or extend the time")}</MGHelperText>
              </MGModalHeader>
              <MGModalBody>
                <br />
                <MGRadioGroupField asList={true} name={"endClassMethod"}>
                  <MGRadioField name={"endClassMethod"} value={"end"}>
                    {t("End Session")}
                  </MGRadioField>
                  <MGRadioField name={"endClassMethod"} value={"extend"}>
                    {t("Extend Session")}
                  </MGRadioField>
                </MGRadioGroupField>

                {values.endClassMethod === "extend" && (
                  <>
                    <MGLabel>{t("Extend By:")}</MGLabel>
                    <MGDurationPickerField name="duration" />
                    {/* <div style={{ display: "flex" }}>
                      <div style={{ width: "100px" }}>
                        <MGNumberBoxField name="hours" placeholder={t("Hours")} />
                      </div>
                      <MGSpacing size={"xs"} />
                      <MGSpacing size={"xs"} />
                      <div style={{ width: "100px" }}>
                        <MGNumberBoxField name="minutes" placeholder={t("Minutes")} />
                      </div>
                    </div> */}
                  </>
                )}
              </MGModalBody>
              <MGModalFooter>
                <MGButton variant="tertiary" onClick={onCancel}>
                  {t("Cancel")}
                </MGButton>
                <MGSpacing size={"md"} />

                {values.endClassMethod === "extend" && (
                  <MGButton
                    variant="primary"
                    isLoading={isSubmitting}
                    disabled={!isValid || isSubmitting}
                    type={"submit"}
                  >
                    {t("Extend")}
                  </MGButton>
                )}

                {values.endClassMethod !== "extend" && (
                  <MGButton
                    variant="primary"
                    type={"submit"}
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    {t("End")}
                  </MGButton>
                )}
                {/* <pre><code>{JSON.stringify(values, null, 2)}</code></pre>
                <pre><code>{JSON.stringify(props, null, 2)}</code></pre> */}
              </MGModalFooter>
            </Form>
          );
        }}
      </Formik>
    </MGModal>
  );
};

ClassEndModal.propTypes = {
  isShowing: PropTypes.bool,
  className: PropTypes.string,
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
};

export default ClassEndModal;
