import "./style.css";
import { MGIcon } from "@/components/icons";
import { MGProgressSpinner } from "@/components/progress";

import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { MGSpacing } from "@/components/spacing";

export const MGLinkButton = ({
  iconLeft,
  isExternalURL,
  to,
  iconRight,
  children,
  disabled,
  isLoading,
  variant,
  ...rest
}) => {
  const linkButtonChildren = () => {
    return (
      <>
        {iconLeft && (
          <span className="--mg-link-button-icon-left">
            <MGIcon icon={iconLeft} />
          </span>
        )}
        {children}
        {iconRight && (
          <span className="--mg-link-button-icon-right">
            <MGIcon icon={iconRight} />
          </span>
        )}
        {isLoading && 
          <>
            <MGSpacing size="xs" />
            <MGProgressSpinner size={24} />
          </>
        }
      </>
    );
  };

  if (!isExternalURL) {
    return (
      <Link
        className={`--mg-link-button ${variant ? "--mg-link-button-" + variant : ""} ${
          disabled ? "--mg-link-button-disabled" : ""
        }`}
        to={to}
        {...rest}
      >
        {linkButtonChildren()}
      </Link>
    );
  } else {
    return (
      <a
        className={`--mg-link-button ${variant ? "--mg-link-button-" + variant : ""} ${
          disabled ? "--mg-link-button-disabled" : ""
        }`}
        href={to.startsWith("http", 0) ? to : `//${to}`}
        target={"_blank"}
        rel={"noreferrer"}
        {...rest}
      >
        {linkButtonChildren()}
      </a>
    );
  }
};

MGLinkButton.propTypes = {
  type: PropTypes.string,
  isExternalURL: PropTypes.bool,
  to: PropTypes.string,
};

MGLinkButton.defaultProps = {
  variant: "primary",
  to: "#",
  isExternalURL: false,
};

export default MGLinkButton;
