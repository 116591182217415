import React from "react";
import PropTypes from "prop-types";
import "./style.css";
import { MGIcon } from "@/components/icons";
import { MGTextbox } from "@/components/inputs";
import classNames from "classnames";
import i18next from "i18next";

export class MGTimePickerControl extends React.Component {
  constructor(props) {
    super(props);
    let currentDate = new Date();
    this.state = {
      increment: this.props.increment ?? 1,
      time: this.props.time ?? {
        hours: currentDate.getHours() > 12 ? currentDate.getHours() - 12 : currentDate.getHours(),
        minutes: currentDate.getMinutes(),
        am_pm: currentDate.getHours() > 12 ? "PM" : "AM",
      },
    };
  }

  /**
   * @returns {string}
   */
  formatTimeInput() {
    return `${this.formatTime(this.state.time.hours)}:${this.formatTime(this.state.time.minutes)} ${
      this.state.time.am_pm
    }`;
  }

  /**
   *
   * @param {number} hoursOrMinutes
   * @returns {string}
   */
  formatTime(hoursOrMinutes) {
    let time = "0" + hoursOrMinutes?.toString();
    if (time.length > 2) {
      time = time.toString().substring(1, 3);
    }
    return time;
  }

  /**
   *
   * @param {number} hours
   */
  addHours(hours) {
    let newHours = this.state.time.hours + hours;
    if (newHours == 13) {
      this.setState((previousState) => ({
        time: {
          ...previousState.time,
          hours: 1,
        },
      }));
      this.toggleAmPm();
    } else if (newHours < 1) {
      this.setState((previousState) => ({
        time: {
          ...previousState.time,
          hours: 12,
        },
      }));
      this.toggleAmPm();
    } else {
      this.setState((previousState) => ({
        time: {
          ...previousState.time,
          hours: newHours,
        },
      }));
    }
    this.timeChange();
  }

  /**
   *
   * @param {number} minutes
   */
  addMinutes(minutes) {
    let newMins = this.state.time.minutes + minutes;
    if (newMins > 59) {
      this.setState((previousState) => ({
        time: {
          ...previousState.time,
          minutes: 0,
        },
      }));
    } else if (newMins < 0) {
      this.setState((previousState) => ({
        time: {
          ...previousState.time,
          minutes: 59,
        },
      }));
    } else {
      this.setState((previousState) => ({
        time: {
          ...previousState.time,
          minutes: newMins,
        },
      }));
    }
    this.timeChange();
  }

  toggleAmPm() {
    this.setState((previousState) => {
      return {
        time: {
          ...previousState.time,
          am_pm: previousState.time.am_pm == "AM" ? "PM" : "AM",
        },
      };
    });
    this.timeChange();
  }

  timeChange() {
    this.setState((previousState) => {
      let time = {
        hours: previousState.time.hours,
        minutes: previousState.time.minutes,
        am_pm: previousState.time.am_pm,
      };
      if (typeof this.props.onChange == "function") {
        this.props.onChange(time);
      }
      return { time: time };
    });
  }

  render() {
    return (
      <div
        className={`--mg-time-picker-control ${classNames({
          "--mg-time-picker-control-unbordered": this.props.unbordered,
        })}`}
      >
        <div className="--mg-time-picker__hours">
          <button onClick={() => this.addHours(1)} className="--mg-updown-button">
            <MGIcon icon="arrow_up"></MGIcon>
          </button>
          <MGTextbox value={this.formatTime(this.state.time.hours)} clearable={false} onChange={(event) => {
            let hours = parseInt(event);
            if (hours > 12) {
              return;
            }
            this.setState((state) => {
              let time = {
                ...state.time,
                hours: hours
              }
              return { time };
            });
            this.timeChange();
          }}></MGTextbox>
          <button onClick={() => this.addHours(-1)} className="--mg-updown-button">
            <MGIcon icon="arrow_down"></MGIcon>
          </button>
        </div>
        <div className="--mg-time-picker__minutes">
          <button onClick={() => this.addMinutes(1 * this.state.increment)} className="--mg-updown-button">
            <MGIcon icon="arrow_up"></MGIcon>
          </button>
          <MGTextbox value={this.formatTime(this.state.time.minutes)} clearable={false} onChange={(event) => {
            let minutes = parseInt(event);
            if (minutes > 59) {
              return;
            }
            this.setState((state) => {
              let time = {
                ...state.time,
                minutes: minutes
              }
              return { time };
            });
            this.timeChange();
          }}></MGTextbox>

          <button onClick={() => this.addMinutes(-1 * this.state.increment)} className="--mg-updown-button">
            <MGIcon icon="arrow_down"></MGIcon>
          </button>
        </div>

        {this.props.showAMPM && (
          <div className="--mg-time-picker__ampm">
            <div
              className={`${classNames({
                active: this.state.time.am_pm == "AM",
              })}`}
            >
              <button className="--mg-ampm-button active" onClick={() => this.toggleAmPm()}>
                {i18next.t("AM")}
              </button>
            </div>
            <div
              className={`${classNames({
                active: this.state.time.am_pm == "PM",
              })}`}
            >
              <button className="--mg-ampm-button" onClick={() => this.toggleAmPm()}>
                {i18next.t("PM")}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

MGTimePickerControl.propTypes = {
  time: PropTypes.object,
  increment: PropTypes.number,
  onChange: PropTypes.func,
  unbordered: PropTypes.bool,
  showAMPM: PropTypes.bool,
};

MGTimePickerControl.defaultProps = {
  showAMPM: true,
};

export default MGTimePickerControl;
