import { useState } from 'react';
import './style.css'; // Import the CSS for styling
import { MGButton } from '@/components/buttons';
import { useTranslation } from 'react-i18next';


const ZendeskButton = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const {t} = useTranslation();

  let timer;

  const handleClick = () => {
    if(!isClicked){       
        setIsClicked(true);

        // Open the Zendesk Chat widget
        if (window.zE) {
        window.zE('webWidget', 'open');
        }

        if(timer){
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            setIsClicked(false);
        }, 3000);
    }
  };

  return (
    <div
      className={`zendesk-button ${(isHovered||isClicked) ? 'hovered' : ''}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={handleClick}
    >
      <MGButton iconLeft={"info"}>{t('Help')}</MGButton>
    </div>
  );
};

export default ZendeskButton;