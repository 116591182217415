import { Form, Formik } from "formik";
import { MGHeading2, MGHelperText, MGLabel } from "@/components/typography";
import { MGInputListBoxField } from "@/components/inputs";
import { MGModal, MGModalBody, MGModalFooter, MGModalHeader } from "@/components/modals";
import { MGButton } from "@/components/buttons";
import { MGSpacing } from "@/components/spacing";
import { MGProgressSpinner } from "@/components/progress";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { MGCheckbox } from "@/components/controls";
import { useState } from "react";

export const TransferStudentsModal = ({ isShowing, initialValues, loading, onSubmit, onCancel, devices }) => {
  const { t } = useTranslation();

  const validationSchema = yup.object({
    devices: yup.array().min(1, t("At least one device must be added to transfer")).required(t("Required")),
  });

  const [all, setAll] = useState(false);

  const submissionHandler = (values, formik) => {
    let device_uuids = [];

    if (!all) {
      if (values.devices && values.devices.length > 0) {
        devices.map((device) => {
          if (
            values.devices.includes(device.devices[0].device_name) ||
            values.devices.includes(`${device.f_name} ${device.l_name}`)
          ) {
            device_uuids.push(device.devices[0].device_uuid);
          }
        });
      }
    } else {
      if (values.devices && values.devices.length > 0) {
        devices.map((device) => {
          device_uuids.push(device.devices[0].device_uuid);
        });
      }
    }

    values.devices = device_uuids;
    let submissionValues = Object.assign({}, values);

    onSubmit(submissionValues, formik);
  };

  return (
    <MGModal isShowing={isShowing}>
      {loading && <MGProgressSpinner isLarge={true} />}
      {!loading && (
        <Formik initialValues={initialValues} onSubmit={submissionHandler} validationSchema={validationSchema}>
          {({ isSubmitting, isValid, setFieldValue, dirty }) => {
            const setAllHandler = (evt) => {
              let device_names = [];
              if (evt.target.checked) {
                devices.map((device) => {
                  device_names.push(device.devices[0].device_name);
                });
              }
              setFieldValue("devices", device_names);
              setAll(evt.target.checked);
            };

            return (
              <Form noValidate autoComplete={"off"}>
                <MGModalHeader>
                  <MGHeading2>{t("Transfer Students")}</MGHeading2>
                  <p>{t("The following students are active in another session")}</p>
                </MGModalHeader>
                <MGModalBody>
                  <MGCheckbox name="allStudents" value={all} onChange={setAllHandler}>
                    Transfer all students
                    {all && <MGHelperText>{t("All students in this list will be transfered")}</MGHelperText>}
                  </MGCheckbox>

                  <br />
                  <br />
                  <MGLabel htmlFor="devices">{t("Devices")}</MGLabel>
                  <MGInputListBoxField
                    name="devices"
                    placeholder={t("Select the devices you would like to transfer")}
                    suggestions={devices?.map((d) => {
                      return d.f_name ? `${d.f_name} ${d.l_name}` : `${d.devices[0].device_name}`;
                    })}
                    allowFreeText={false}
                  />
                  <MGModalFooter>
                    <MGButton variant="tertiary" onClick={onCancel}>
                      {t("Cancel")}
                    </MGButton>
                    <MGSpacing size={"md"} />
                    <MGButton variant="primary" disabled={isSubmitting || !dirty || !isValid} type={"submit"}>
                      {t("Transfer Students")}
                    </MGButton>
                  </MGModalFooter>
                </MGModalBody>
              </Form>
            );
          }}
        </Formik>
      )}
    </MGModal>
  );
};

TransferStudentsModal.propTypes = {
  isShowing: PropTypes.bool,
  initialValues: PropTypes.any, // FIXME: Fixme
  loading: PropTypes.bool,
  devices: PropTypes.array,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default TransferStudentsModal;
