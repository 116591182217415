import PropTypes from "prop-types";
import "./style.css";
import ReactDOM from "react-dom";
import { useEffect, useRef, useState } from "react";

export const MGMenu = ({ trigger, children, onOutsideClick, anchorX = "center", anchorY = "center", offsetX = 0, offsetY = 0 }) => {
  const menu = useRef();
  const overlay = useRef();

  const [isOpen, setIsOpen] = useState(false);

  /**
   * @description This function applies the necessary style attributes to the background overlay
   */
  function styleBackgroundOverlay() {
    overlay.current.style.position = "fixed";
    overlay.current.style.top = "0px";
    overlay.current.style.left = "0px";
    overlay.current.style.width = "100%";
    overlay.current.style.height = "100%";
    overlay.current.style.zIndex = "1000";
    overlay.current.style.overflow = "hidden";
  }
  
  function setMenuStartingCoords() {
    let triggerDOMRectangle;
    if (trigger) {
      triggerDOMRectangle = trigger.current.children[0].getBoundingClientRect();

    }
    

    if(menu.current){
      // debugger
      //Depending on the x-anchor, position the menu horizontally
      switch(anchorX){
        case "right":
          //menu.current.style.left = triggerDOMRectangle.right - menu.current.getBoundingClientRect().width + offsetX + "px";
          menu.current.style.left = triggerDOMRectangle.x + triggerDOMRectangle.width / 2 - menu.current.getBoundingClientRect().width + offsetX + "px";
          break;
        case "left":{
          menu.current.style.left = triggerDOMRectangle.x + offsetX + "px";
          break;
        }
        case "dead-center":
          menu.current.style.left = triggerDOMRectangle.x + triggerDOMRectangle.width / 2 - menu.current.getBoundingClientRect().width/2 + offsetX + "px";
          break;
        case "center":
        default:
          menu.current.style.left = triggerDOMRectangle.x + triggerDOMRectangle.width / 2 + offsetX + "px";
          break;
      }

       //Depending on the y-anchor, position the menu vertically
       switch(anchorY){
        case "top":
          menu.current.style.top = triggerDOMRectangle.y + offsetY +  "px";
          break;
        case "bottom":
          menu.current.style.top = triggerDOMRectangle.y + offsetY + triggerDOMRectangle.height + "px";
          break;
        case "dead-center":
            menu.current.style.top = triggerDOMRectangle.y - menu.current.getBoundingClientRect().height/2 + offsetY +  triggerDOMRectangle.height / 2 + "px";
            break;
        case "center":
        default:
          menu.current.style.top = triggerDOMRectangle.y + offsetY + triggerDOMRectangle.height / 2 + "px";
          break;
      }

      // if(anchorX === "center")
      // {
      //   menu.current.style.left = triggerDOMRectangle.x + triggerDOMRectangle.width / 2 + "px";
      // } 

      // if(anchorX === "right"){
      //   menu.current.style.left = triggerDOMRectangle.x + triggerDOMRectangle.width + "px";
      // }

      // if(anchorX === "left"){
      //   menu.current.style.left = triggerDOMRectangle.x + "px";
      // }

      // if(anchorY === "center")
      // {
      //   menu.current.style.top = triggerDOMRectangle.y + triggerDOMRectangle.height / 2 + "px";
      // }
    }
  }

  function positionMenu() {
    

    if (menu.current) {
      //First, determine the starting point of where the menu should be positioned, relative to the triggering HTML element
      setMenuStartingCoords()

      //Next, check if the menu does not go below the bottom of the screen, if it does, shift it upward
      let bottomDistance = getDistanceOfMenuBottomToBottomOfScreen(menu);
      if (bottomDistance < 0) {
        menu.current.style.top = menu.current.getBoundingClientRect().top + bottomDistance + "px";
      }

      //Check if the menu does not go over the right edge of the screen, if it does, shift it left
      let rightDistance = getDistanceOfMenuRightToRightOfScreen(menu);
      if (rightDistance < 0) {
        menu.current.style.left = menu.current.getBoundingClientRect().left + rightDistance + "px";
      }

      //make menu scrollable if it exceeds the height of the viewport
      if (menu.current.getBoundingClientRect().height > window.innerHeight) {
        menu.current.style.height = window.innerHeight + "px";
        menu.current.style.overflowY = "overlay";
        menu.current.style.top = "0px";
      }
    }
  }

  function getScrollbarWidth() {
    // Creating invisible container
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.overflow = "scroll"; // forcing scrollbar to appear
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement("div");
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
  }

  function getDistanceOfMenuBottomToBottomOfScreen(menu) {
    var space = window.innerHeight - menu.current.getBoundingClientRect().y - menu.current.offsetHeight;

    return space;
  }

  function getDistanceOfMenuRightToRightOfScreen(menu) {
    var space =
      window.innerWidth -
      menu.current.getBoundingClientRect().x -
      getScrollbarWidth() - //Be sure to include the width of a scrollbar on the right if page is scrollable
      menu.current.offsetWidth;

    return space;
  }

  function toggle() {
    setIsOpen(!isOpen);
  }

  function closeMenu() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (trigger) {
      if (overlay.current) styleBackgroundOverlay();

      trigger.current.addEventListener("click", toggle);
      window.addEventListener("resize", closeMenu);

      return () => {
        if (trigger.current) trigger.current.removeEventListener("click", toggle);
        window.removeEventListener("resize", closeMenu);
      };
    }
  });

  useEffect(()=>{
    if(isOpen){
      positionMenu();
    }
  }, [isOpen])

  const onOutsideClickHandler = (evt) => {
    setIsOpen(false);
    if (typeof onClick === "function") {
      onOutsideClick(evt);
    }
  };

  return (
    <>
      {isOpen
        ? ReactDOM.createPortal(
            <div
              id="mg-overlay-id"
              className="--mg-menu-overlay-background"
              ref={overlay}
              onClick={onOutsideClickHandler}
            >
              <div className={`--mg-menu`} ref={menu}>
                {children}
              </div>
            </div>,
            document.body
          )
        : null}
    </>
  );
};

MGMenu.propTypes = {
  trigger: PropTypes.any,
  children: PropTypes.any,
  onOutsideClick: PropTypes.func,
  anchorX: PropTypes.oneOf(["left", "right", "center", "dead-center"]),
  anchorY: PropTypes.oneOf(["top", "bottom", "center", "dead-center"]),
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
};

MGMenu.defaultProps = {};

export default MGMenu;
