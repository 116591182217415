import "./style.css";
import classNames from "classnames";
import PropTypes from "prop-types";

import { MGIcon } from "@/components/icons";
import { MGMenu, MGMenuItem, MGMenuTrigger } from "@/components/menu";
import { MGSpacing } from "@/components/spacing";
import { useMemo, useRef } from "react";
import { manualRoleChangeVar } from "@/cache";

import MGAvatar from "@/components/avatar/MGAvatar";
import { MGButton, MGIconButton } from "@/components/buttons";
import { useTranslation } from "react-i18next";
import { MGFlexBox, MGFlexBoxSpacer } from "@/components/layout";

export const MGRolesPicker = ({ currentUser, schoolUsers, roles, onLogout, onRoleChange }) => {
  const menuRef = useRef();
  const schoolRef = useRef();

  const { t } = useTranslation();

  const onRoleChangeHandler = (event) => {
    manualRoleChangeVar(true);
    if (typeof onRoleChange == "function") {
      onRoleChange(event);
    }
  };

  const onSchoolChangeHandler = (event) => {
    if (typeof onRoleChange == "function") {
      onRoleChange(event.role_id, event.school_id);
    }
  };

  //This function is only needed because of how RolesPicker is composed
  //An x-offset in px needs to be calculated for the "Role" MGMenu (On the right of rolespicker)
  //This function should return half of the width of the trigger and add 2px for the border weight of the entire RolesPicker component for the Menu to be perfecty positioned
  const getRolePickerMenuOffset = useMemo(() => {
    if (menuRef?.current) {
      const boundingRectangle = menuRef.current.getBoundingClientRect();

      return Math.round(boundingRectangle.width / 2) + 2;
    } else {
      return 0;
    }
  }, [menuRef, menuRef?.current]);
  return (
    <>
      <div className="--mg-roles-picker">
        <div className={`--mg-roles-picker_schools ${classNames({ active: schoolUsers?.length > 1 })} `}>
          <MGMenuTrigger ref={schoolRef}>
            <div style={{ alignItems: "center", display: "flex", width: "330px" }}>
              <MGSpacing size={"sm"} />
              <MGAvatar bordered={false} letter={currentUser?.school?.name} isSmall={true} />
              <MGSpacing size={"md"} />
              {currentUser?.school?.school_type?.id == "1" && <MGIcon icon={"school"} />} {/* School */}
              {currentUser?.school?.school_type?.id == "2" && <MGIcon icon={"district"} />} {/* District */}
              {currentUser?.school?.school_type?.id == "3" && <MGIcon icon={"corporate"} />} {/* Corporate */}
              {currentUser?.school?.school_type?.id == "4" && <MGIcon icon={"partner"} />} {/* Partner */}
              <MGSpacing size={"md"} />
              <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                {currentUser?.school?.name}
              </span>
              <MGSpacing size={"sm"} />
            </div>
          </MGMenuTrigger>
        </div>
        {schoolUsers?.length > 1 && (
          <MGMenu trigger={schoolRef} anchorX="left" anchorY="bottom" offsetX={-2} offsetY={1}>
            {schoolUsers?.map((user, index) => (
              <div key={index}>
                {!user.is_current && (
                  <MGMenuItem>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          maxWidth: "90%",
                          display: "flex",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        <MGSpacing size={"sm"} />
                        {user?.school?.school_type?.id == "1" && <MGIcon icon={"school"} />} {/* School */}
                        {user?.school?.school_type?.id == "2" && <MGIcon icon={"district"} />} {/* District */}
                        {user?.school?.school_type?.id == "3" && <MGIcon icon={"corporate"} />} {/* Corporate */}
                        {user?.school?.school_type?.id == "4" && <MGIcon icon={"partner"} />} {/* Partner */}
                        <MGSpacing size={"md"} />
                        <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                          {user?.school?.name == "Parent Placeholder" ? "Parent Dashboard" : user?.school?.name}
                        </span>
                        <MGSpacing size={"sm"} />
                      </div>
                      <div
                        style={{
                          width: "20%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {/* Admin */}
                        {user.role_id == "1" && (
                          <MGIconButton variant="primary" onClick={() => onSchoolChangeHandler(user)} icon={"admin"} />
                        )}{" "}
                        {/* Super */}
                        {user.role_id == "2" && (
                          <MGIconButton variant="primary" onClick={() => onSchoolChangeHandler(user)} icon={"admin"} />
                        )}{" "}
                        {/* Teacher */}
                        {user.role_id == "3" && (
                          <MGIconButton
                            variant="primary"
                            onClick={() => onSchoolChangeHandler(user)}
                            icon={"teacher"}
                          />
                        )}{" "}
                        {/* Parent */}
                        {user.role_id == "4" && (
                          <MGIconButton variant="primary" onClick={() => onSchoolChangeHandler(user)} icon={"parent"} />
                        )}{" "}
                      </div>
                    </div>
                  </MGMenuItem>
                )}
              </div>
            ))}
          </MGMenu>
        )}
        <div className="--mg-roles-picker_divider"></div>
        <div className="--mg-roles-picker_roles" ref={menuRef}>
          <MGMenuTrigger>
            {currentUser?.role_id == "1" && <MGIcon icon={"admin"} />} {/* Admin */}
            {currentUser?.role_id == "2" && <MGIcon icon={"admin"} />} {/* Super */}
            {currentUser?.role_id == "3" && <MGIcon icon={"teacher"} />} {/* Teacher */}
            {currentUser?.role_id == "4" && <MGIcon icon={"parent"} />} {/* Parent */}
          </MGMenuTrigger>
        </div>
        <MGMenu trigger={menuRef} anchorX="right" anchorY="bottom" offsetX={getRolePickerMenuOffset} offsetY={10}>
          <>
            {roles?.map((role, index) => (
              <MGMenuItem key={index}>
                <MGFlexBox>
                  {/* Admin */}
                  {role.id == "1" && (
                    <div onClick={() => onRoleChangeHandler(role.id)}>
                      <MGIcon icon={"admin"} />
                      <MGSpacing size={"xl"} />
                      <span>{t("Admin")}</span>
                    </div>
                  )}{" "}
                  {/* Super */}
                  {role.id == "2" && (
                    <div onClick={() => onRoleChangeHandler(role.id)}>
                      <MGIcon icon={"admin"} />
                      <MGSpacing size={"xl"} />
                      <span>{t("Admin")}</span>
                    </div>
                  )}
                  {/* Teacher */}
                  {role.id == "3" && (
                    <div onClick={() => onRoleChangeHandler(role.id)}>
                      <MGIcon icon={"teacher"} />
                      <MGSpacing size={"xl"} />
                      <span>{t("Teacher")}</span>
                    </div>
                  )}
                  {/* Parent */}
                  {role.id == "4" && (
                    <div onClick={() => onRoleChangeHandler(role.id)}>
                      <MGIcon icon={"parent"} />
                      <MGSpacing size={"xl"} />
                      <span>{t("Parent")}</span>
                    </div>
                  )}
                  <MGFlexBoxSpacer />
                </MGFlexBox>
              </MGMenuItem>
            ))}
          </>

          <MGMenuItem>
            <MGFlexBox>
              <MGIcon icon={"logout"} />
              <MGButton
                onClick={() => {
                  if (typeof onLogout === "function") {
                    onLogout();
                  }
                }}
                variant={"tertiary"}
              >
                {t("Logout")}
              </MGButton>
              <MGFlexBoxSpacer />
            </MGFlexBox>
          </MGMenuItem>
        </MGMenu>
      </div>
      <MGSpacing size={"sm"} />
    </>
  );
};

MGRolesPicker.propTypes = {
  currentUser: PropTypes.object,
  schoolUsers: PropTypes.any,
  roles: PropTypes.any,
  onLogout: PropTypes.func,
};

export default MGRolesPicker;
