import "./wdyr"; // <--- first import
import { createRoot, Root } from 'react-dom/client';
import React from 'react';
import App from "./App";
import "./app/style/index.css"; // double import, should be trimmed automatically
import { BrowserRouter } from "react-router-dom";
import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink } from "@apollo/client";

import WebRTCShare from "./app/services/webRTC/web-rtc-share";
import TeacherWebRTCShare from "./app/services/webRTC/web-rtc-share-teacher";
import TabControlService from "./app/services/tab-control/tab-control.service";

import { lazy, Suspense } from "react";
// import PlanRouter from "@/app/features/plan/router";
const PlanRouter = lazy(() => import("@/app/features/plan/router"));

// import ModesRouter from "@/app/features/modes/router";
const ModesRouter = lazy(() => import("@/app/features/modes/router"));

// import ReportsRouter from "@/app/features/reports/router";
const ReportsRouter = lazy(() => import("@/app/features/reports/router"));

// import UsersRouter from "./app/features/users/router";
const UsersRouter = lazy(() => import("@/app/features/users/router"));

import { MGBannerProvider } from "./components/banner";

import { SiteErrorFallback } from "@/app/app-components/error-boundaries/SiteErrorFallback";
import MGNotificationProvider from "./components/notification/MGNotificationProvider";
import { HomePage } from "./app/features/home/HomePage";
import { NotFoundPage } from "./app/ErrorPages/NotFoundPage";

import { withAuth } from "@/app/features/auth/hoc/withAuth";
import { LoginPage } from "@/app/features/auth/LoginPage";

import { Routes, Route } from "react-router";

import { MGSnackbarProvider } from "@/components/snackbar";
import { ErrorBoundary } from "react-error-boundary";

import { setContext } from "@apollo/client/link/context";

import { cache, typeDefs } from "./cache";
import { Observable, BehaviorSubject } from "rxjs";
import { logger } from "@/app/services/logging/Logger";

import ThemeProvider from "./app/style/ThemeProvider";
// direct from https://www.apollographql.com/docs/react/networking/authentication/
// NB!!! Copy .env.sample to .env and change VITE_API_URL there for local dev / different GraphQL API
//const isDevEnv = (!process.env.NODE_ENV || process.env.NODE_ENV === "development");


const httpLink = createHttpLink({
  uri: Config.API_BASE_URL  + "/graphql",
  // credentials: 'same-origin'
  credentials: 'include',
   fetchOptions: {
    mode: 'cors',
  },
});

const _isRefreshingToken = new BehaviorSubject<boolean>(false);
export function isRefreshingToken(): Observable<boolean>{
  return _isRefreshingToken.asObservable()
}

 const authLink = setContext((_, { headers }) => {

  return {
    headers: {
      ...headers,
      // "Access-Control-Allow-Origin": config.API_BASE_URL,
      // authorization: token ? `Bearer ${token}` : "",
    },
  };
});

/// The code below is to silence console errors that are currently complaining about defaultProps being deprecated in React 18
// eslint-disable-next-line no-console
const originalErr = console.error;

let hasBeenWarned_defaultProps = false;
// eslint-disable-next-line no-console
console.error = (message, ...args) => {
  if (typeof message === 'string' && message.includes('Support for defaultProps will be removed from function components in a future major release.')) {
    if(!hasBeenWarned_defaultProps)
    {
      // eslint-disable-next-line no-console
      console.warn("Support for defaultProps will be removed from function components in a future major release. Component-level logs have been silenced, to avoid flooding the console. You have been warned")
      hasBeenWarned_defaultProps = true;
    }

    return;
  }
  originalErr(message, ...args);
};

import "./i18n";
import Config from "@/config";

import UpdateServiceWorkerModal from "./UpdateServiceWorkerModal";
import LogoutPage from "./app/features/auth/LogoutPage";
// import { NatsWebsocketProvider } from "./app/app-components/providers/NatsWS/NatsWebsocketProvider";
import { NatsService } from "./app/services/nats/nats-service";
import { AuthService } from "./app/services/auth/AuthService";
import { onError } from "@apollo/client/link/error";
import HealthPage from "./app/features/health/HealthPage";
import i18n from "./i18n";
import MGTranslationsProvider from "./components/translations/MGTranslations/MGTranslationsProvider";
import { getNoTextLogoFilePath } from "./app/utils/theming/images";
import ZendeskButton from "./app/app-components/ZendeskHelpButton/ZendeskHelpButton";

const TeachRouter = lazy(() => import("@/app/features/teach/router"));
const ReviewRouter = lazy(() => import("@/app/features/review/router"));
const DevicesRouter = lazy(() => import("@/app/features/devices/routes/devices.router"));
const StudentsRouter = lazy(() => import("@/app/features/students/routes/students.router"));

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors)
  for (const err of graphQLErrors) {
    if (err.extensions) {
      switch (err.extensions.code) {
        case 'UNAUTHENTICATED':
          authService.checkIfNewAuthTokenShouldBeRequested();
          // return forward(operation)
      }
    }
  }
  if (networkError) logger.error(`[Network error]: ${networkError}`);
});



const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache,
  typeDefs,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
  credentials: 'include'
});



export const authService = new AuthService(client);

export const webRtcShare = WebRTCShare(client);
export const teacherScreenShare = TeacherWebRTCShare(client);

export const tabControlService = TabControlService(client);

const HomePageWithAuth = withAuth(HomePage, {});

// loading component for suspense fallback
const ApplicationLoader = () => {
  const themedImagePath = getNoTextLogoFilePath();
  return (
  <div className="App">
      <div style={{marginTop:"25%", marginLeft:"50%"}}>
          <img src={themedImagePath} height={"80px"} width={"80px"}/>
      </div>
  </div>
)};

const MGTranslate = (text: string, ...args: any) => {
  try {
    return i18n.t(text, ...args);
  } catch (e) {
    logger.log("Translation error: ", e);
    return text;
  }
};

let root: Root | null | undefined;

const container = document.getElementById('root');

if(!root && container){
  root = createRoot(container);
}


// React 18
if(root)
{
  root.render(
    <React.StrictMode>
    <ApolloProvider client={client}>
      <ErrorBoundary
        FallbackComponent={SiteErrorFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
      >
        <ThemeProvider>
        <Suspense fallback={<ApplicationLoader />}>
        <MGTranslationsProvider translateFunction={MGTranslate}>
          <MGBannerProvider>
            <MGSnackbarProvider>
              <MGNotificationProvider>
                  <BrowserRouter>
                      <Routes>
                        {/* Auth Routes */}

                          <Route path="/login" element={<LoginPage />} />
                          <Route path="/logout" element={<LogoutPage />} />
                        
                        <Route path="/" element={<App />}>
                          <Route index element={<HomePageWithAuth />} />

                          {/* Feature Routes */}
                          <Route path="plan/*" element={<PlanRouter />} />
                          <Route path="teach/*" element={<TeachRouter />} />
                          {/* <Route path="classes/*" element={<ClassesRouter />} /> */}
                          <Route path="students/*" element={<StudentsRouter />} />
                          {/* <Route path="student/*" element={<StudentRouter />} /> */}
                          <Route path="modes/*" element={<ModesRouter />} />
                          <Route path="devices/*" element={<DevicesRouter />} />
                          {/* <Route path="device/*" element={<DeviceRouter />} /> */}
                          <Route path="devices/*" element={<DevicesRouter />} />
                          <Route path="reports/*" element={<ReportsRouter />} />
                          <Route path="users/*" element={<UsersRouter />} />
                          <Route path="review/*" element={<ReviewRouter/>}/>

                          <Route path="tdhealthcheck/*" element={<HealthPage/>}/>

                          {/* Catch all Route */}
                          <Route path="*" element={<NotFoundPage />} />
                        </Route>
                      </Routes>
                  </BrowserRouter>
                  <UpdateServiceWorkerModal/>
                  <ZendeskButton/>
              </MGNotificationProvider>
            </MGSnackbarProvider>
          </MGBannerProvider>
        </MGTranslationsProvider>
        </Suspense>
        </ThemeProvider>
        </ErrorBoundary>
    </ApolloProvider>
    </React.StrictMode>
  );
}
