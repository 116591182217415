import MGSurface from "@/app/app-components/MGSurface/MGSurface";
import { useTranslation } from "react-i18next";
import "./style.css";
import { MGHeading1 } from "@/components/typography";
import { getAMPMTime } from "@/app/hooks/useDateFormattingUtils";
import config from "@/config";
import { useEffect, useMemo, useRef } from "react";

export const WelcomeSplash = ({ nextClass, currentUser }) => {
  const { t } = useTranslation();
  const welcomeRef = useRef();

  const classes = useMemo(() => {
    const classes = ["mg-home-welcome"];

    const { COLOR_THEME } = config;
    if (COLOR_THEME.toLowerCase() == "edmesh") {
      classes.push("edmesh-homepage");
    } else {
      classes.push("mg-homepage");
    }

    if (document.body.dir === "rtl") {
      classes.push("rtl");
    }
    return classes;
  }, [document.body.dir, config]);

  return (
    <MGSurface>
      <div className={classes.join(" ")} ref={welcomeRef}>
        <h1 className="heading-jumbo">
          {t("Welcome back,")}
          <br />
          {currentUser?.firstname}!
        </h1>

        <br />
        <br />

        {nextClass && (
          <div style={{ maxWidth: "250px" }}>
            <MGHeading1>
              {t("Your next class starts at {{%s}}", { "%s": getAMPMTime(nextClass?.start_time) })}
            </MGHeading1>
          </div>
        )}
      </div>
    </MGSurface>
  );
};
